import React from 'react';

import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Player from './pages/v/Player';


function App() {
  
  return (
    <div className="App">
     <BrowserRouter>
       <Routes>
            <Route path="/" element={<h1>Home</h1>} />
           <Route path="/v/:postId" element={<Player/>} />
       </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;


import React, {useState, useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom'
import {X} from '@phosphor-icons/react'
import {motion} from 'framer-motion'
import '../../css/global.css'



import {ModalAnimation} from './components/AnimateOption'
import { Header } from './components/Header'
import { VideoPlayer } from './components/Hls'
import { RdbChat } from './components/RdbChat'
import { Product } from './components/Products'
import {ref, push, update, onValue, get, set, child, onDisconnect} from "firebase/database";
import {realdb} from "../../plugins/firebase";
import dayjs from "dayjs";

import {Cookies} from 'react-cookie'
// import {randomUUID} from "node:crypto";

import {Viewer, Post, HeaderInfo, ProductInfo, Quiz} from "./states/PlayerInt";


const Modal = ({close}: {close: () => void}) => {
	return (
		<div className={'bg-[#161618] min-w-[300px] p-4 rounded-lg text-white text-[14px]'} style={{boxShadow: '-1px -1px 80px 0px rgba(255, 255, 255, 0.15), 0px 0px 76px 2px rgba(0, 0, 0, 0.55)'}}>
			<div className={'text-black absolute top-[16px] right-[16px]'}>
				<button type={'button'} onClick={close}>
					<X size={24} color={'#a1a1a1'}/>
				</button>
			</div>
			<div className={'pt-5'}>
				<div className={'flex items-center justify-center mb-5'}>
					<img
						src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/baf191aff459f5571ff0f4d42013d566.png"
						alt=""/>
				</div>
				
				<div className={'text-center'}>
					<strong className={'text-[20px]'}>내가 원하는 상담방법은?</strong>
					<p className={'text-[12px] mt-1'}>
						방송 종료 후 혜택은 변경될 수 있습니다.
					</p>
				</div>
				
				<div className={'max-w-[250px] mx-auto mt-5'}>
					<div className={'mt-[-5px]'}>
						<motion.a whileTap={{scale: 0.9}} href={'tel:164470091'}><img
							src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/31fd811d38f4434511935a792b12202d.png"
							alt=""/></motion.a>
					</div>
					<div className={'mt-[-5px]'}>
						<motion.button whileTap={{scale: 0.9}} type={'button'}><img
							src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/2626be02fbb9d89b3eb30461689abd95.png"
							alt=""/></motion.button>
					</div>
					<div className={'mt-[-5px]'}>
						<motion.button whileTap={{scale: 0.9}} type={'button'}><img
							src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/28e6a960a26b6d0532899afdb1e10409.png"
							alt=""/></motion.button>
					</div>
				</div>
			</div>
		</div>
	)
}

const Login = ({post, postId, close}: {post: Post | null,postId: string | undefined,close: () => void}) => {
	
	const joinChat = (e: any) => {
		e.preventDefault();
		
		const formdata = new FormData(e.target);
		
		// realdb viewer same uid update search key
		const cookies = new Cookies()
		const nickname = formdata.get('nickname') as string
		const viewerKey = cookies.get('oid') as string
		
		const viewerRef = ref(realdb, `viewer/${postId}/${viewerKey}`)
		
		
		update(viewerRef, {
			userNick: nickname,
		})
		
		
		if(post?.type === 'live') {
			const viewerRef2 = ref(realdb, `rooms/${postId}/${viewerKey}`)
			update(viewerRef2, {
				userNick: nickname,
			})
		}
		
		
		sessionStorage.setItem('nickname', nickname)
		
		close()
		
	}
	const [allowChecked, setAllowChecked] = useState(false)
	return (
		<div className={'bg-black w-[300px] rounded-lg text-white text-[14px] overflow-hidden'}
		     style={{boxShadow: '-1px -1px 80px 0px rgba(255, 255, 255, 0.15), 0px 0px 76px 2px rgba(0, 0, 0, 0.55)'}}>
			<div className={'text-black absolute top-[16px] right-[16px]'}>
				<button type={'button'} onClick={close}>
					<X size={24} color={'#a1a1a1'}/>
				</button>
			</div>
			<div>
				<div className={'px-[16px] pb-[px] pt-[24px] text-center mt-3'}>
					<div className={'font-bold text-[18px]'}>채팅 참여</div>
					<div className={'text-[#d8d8d8] mt-3 text-[14px]'}>채팅 참여를 위해 아래 정보를 입력해 주세요.</div>
				</div>
				
				<form onSubmit={joinChat}>
					<div className={'p-[16px] space-y-[12px]'}>
						<div>
							<input type="text"
							       name={'name'}
							       className={'py-[4px] pl-[8px] pr-[13px] bg-[#1a1a1a] border border-[#212121] rounded-[3.2px] w-full text-[rgba(199,205,228,1)]'}
							       placeholder={'홍길동'}
							       required={true}
							/>
						</div>
						<div>
							<input type="number"
							       name={'phone'}
							       className={'py-[4px] pl-[8px] pr-[13px] bg-[#1a1a1a] border border-[#212121] rounded-[3.2px] w-full text-[rgba(199,205,228,1)]'}
							       placeholder={'01012345678'}
							       required={true}
							/>
						</div>
						<div>
							<input type="text"
							       name={'nickname'}
							       className={'py-[4px] pl-[8px] pr-[13px] bg-[#1a1a1a] border border-[#212121] rounded-[3.2px] w-full text-[rgba(199,205,228,1)]'}
							       placeholder={'아이폰이좋아'}
							       required={true}
							/>
						</div>
					</div>
					
					<div className={'p-[16px] text-[#a3a3a3] text-[14px] bg-[#161616]'}>
						<div>유의사항</div>
						<ul className={'list-disc text-[12px] ml-[20px]'}>
							<li>닉네임은 채팅 참여 시 노출되며, 입력된 정보는 수정할 수 없습니다.</li>
							<li>이벤트 당첨 시 활용되므로 정확하게 입력해주세요.</li>
						</ul>
					</div>
					<div className={'px-[16px] py-[20px] flex items-center justify-between'}>
						<div className={'flex items-center gap-1'}>
							<input checked={allowChecked} onChange={() => {
								setAllowChecked((prev) => {
									return !prev
								})
							}} id={'allow'} type="checkbox" name="" className={'hidden'}/>
							<label htmlFor={'allow'}>
								{
									allowChecked ? (
										<img
											src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/8282af093f2429d358a8c9024171f315.png"
											alt=""/>
									) : (
										<img
											src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/5176d3aaf11f1095ec5cdf04edea9d34.png"
											alt=""/>
									)
								}
							</label>
							<label htmlFor="allow" className={'text-[14px]'}>
								개인정보 수집 및 이용 동의 (필수)
							</label>
						</div>
						<span className={'text-[rgba(161,161,161,1)]'}>약관보기</span>
					</div>
					<div className={'flex'}>
						<button type={'button'} onClick={close} className={'py-3 px-4 w-[50%] bg-[#272727]'}>취소</button>
						<button type={'submit'} disabled={!allowChecked}
						        className={'bg-[#e6007e] w-[50%] disabled:bg-[#808080] disabled:text-[#aaaaaa]'}>참여하기
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}


// 이벤트 모달
const EventModal = ({quiz, close}: { quiz: Quiz | undefined, close: () => void }) => {
	const [userAnswer, setUserAnswer] = useState(0)
	
	const closeEvent = () => {
		if (quiz?.state === 'end') {
			close()
		} else {
			let closeAlert = window.confirm('창을 닫으면 이벤트에 참여 할 수 없습니다. 그래도 닫으시겠습니까?')
			if (closeAlert) {
				close()
			}
		}
	}
	return (
		<div
			className={`bg-[#161618] w-[300px]  p-[20px] rounded-lg text-white text-[14px] relative`}
			style={{boxShadow: '-1px -1px 80px 0px rgba(255, 255, 255, 0.15), 0px 0px 76px 2px rgba(0, 0, 0, 0.55)'}}>
			<div className={'text-black absolute top-[16px] right-[16px]'}>
				<button type={'button'} onClick={() => {
					closeEvent()
				}}>
					<X size={24} color={'#a1a1a1'}/>
				</button>
			</div>
			<div className={'pt-5 text-center'}>
				<div className={'mb-[26px]'}>
					<div
						className={'bg-[rgba(255,71,168,1)] relative w-[42px] h-[42px] mx-auto rounded-3xl text-[23px] flex items-center justify-center font-bold'}>
						{quiz?.ingTime}
						<motion.div
							animate={{
								scale: quiz?.state !== 'end' ? [0, 1.05, 0.98, 1] : 1,
							}}
							transition={{
								duration: 0.5,
								repeat: quiz?.state !== 'end' ? Infinity : 0,
								repeatDelay: 0.55,
							}}
							className={'bg-[rgba(244,61,168,0.15)] w-[64px] h-[64px] absolute top-[-25%] left-[-25%] rounded-[100%]'}></motion.div>
					</div>
				</div>
				<div className={`w-[260px]`}>
					{
						quiz?.type === 'ox' ? (
							<div className={''}>
								<div className={''}>
									<div className={'flex items-center justify-center mb-5 text-[18px] leading-[25px]'}
									     style={{letterSpacing: '-1px'}}>
										{quiz?.questions}
									</div>
									<div className={'max-w-[220px] mx-auto'}>
										<div
											className={''}>
											{
												quiz.state !== 'end' ? (
													<div>
														<div
															className={'bg-[#141414] h-[90px] flex items-center justify-between'}>
															<button type={'button'} onClick={() => {
																setUserAnswer(1)
															}}>
																{
																	userAnswer !== 1? (
																		<img
																			src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/dd2dca3b598525c601e47803a6a78aec.png"
																			alt="" className={'w-[90px] h-[90px]'}/>
																	) : (
																		<img
																			src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/39b1b819cc41690a900eb9d93d4bb768.png"
																			alt="" className={'w-[90px] h-[90px]'}/>
																	)
																}
															
															</button>
															<button type={'button'} onClick={() => {
																setUserAnswer(2)
															}}>
																{userAnswer !== 2 ? (
																	<img
																		src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/26a23e1b49b7f3816b5f32c8b0be5d8a.png"
																		alt=""/>
																) : (
																	<img
																		src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/3c39f5ba9f5dcc8052aa1b68ba38cc51.png"
																		alt=""/>
																)}
															</button>
														</div>
													</div>
												) : (
													<div>
														<div
															className={'bg-[#141414] h-[90px] flex items-center justify-between'}>
															<div>
																{
																	userAnswer !== 1 ? (
																		<img
																			src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/dd2dca3b598525c601e47803a6a78aec.png"
																			alt="" className={'w-[90px] h-[90px]'}/>
																	) : (
																		<img
																			src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/39b1b819cc41690a900eb9d93d4bb768.png"
																			alt="" className={'w-[90px] h-[90px]'}/>
																	)
																}
															
															</div>
															<div>
																{
																	userAnswer !== 2 ? (
																		<img
																			src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/26a23e1b49b7f3816b5f32c8b0be5d8a.png"
																			alt=""/>
																	) : (
																		<img
																			src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/3c39f5ba9f5dcc8052aa1b68ba38cc51.png"
																			alt=""/>
																	)
																}
															
															</div>
														</div>
													</div>
												)
											}
										
										</div>
									</div>
								</div>
							</div>
						) : (
							<div>
								<div className={'flex items-center justify-center mb-5 text-[18px] leading-[25px]'}
								     style={{letterSpacing: '-1px'}}>
									{quiz?.questions}
								</div>
								<div className={'mx-auto text-left'}>
									<div className={'space-y-2'}>
										{
											quiz?.state !== 'end' ? (
												
												<div>
													<button type={'button'} onClick={() => {
														setUserAnswer(1)
													}}
													        className={`${userAnswer !== 1 ? 'bg-[#181818]' : 'bg-gradient-to-br'} w-full to-[#ff35dd] from-[#ea3379] p-[10px] rounded-[10px] flex items-center gap-3`}>
														{
															userAnswer !== 1 ? (
																<img
																	src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/7328377b6f890508855a3d93f141d3f3.png'}
																	alt={''}/>
															) : (
																<img
																	src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/8aa2c15a59e6a99609a844260eeb38f0.png'}
																	alt={''}/>
															)
														}
														{quiz?.answerList[0].text}
													</button>
													<button type={'button'} onClick={() => {
														setUserAnswer(2)
													}}
													        className={`${userAnswer !== 2 ? 'bg-[#181818]' : 'bg-gradient-to-br'} w-full to-[#ff35dd] from-[#ea3379] p-[10px] rounded-[10px] flex items-center gap-3`}>
														{
															userAnswer !== 2 ? (
																<img
																	src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/074a44a85dc7b0c83cd02e88cc4c987c.png'}
																	alt={''}/>
															) : (
																<img
																	src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/0fd74fb491118bb78e09c1b648329fe3.png'}
																	alt={''}/>
															)
														}
														{quiz?.answerList[1].text}
													</button>
													<button type={'button'} onClick={() => {
														setUserAnswer(3)
													}}
													        className={`${userAnswer !== 3 ? 'bg-[#181818]' : 'bg-gradient-to-br'} w-full to-[#ff35dd] from-[#ea3379] p-[10px] rounded-[10px] flex items-center gap-3`}>
														{userAnswer !== 3 ? (
															<img
																src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/982c9010e709ecd8558f285b2bfa62b2.png'}
																alt={''}/>
														) : (
															<img
																src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/ffffd9db37d90dc7c7038e7218bebdf5.png'}
																alt={''}/>
														)}
														{quiz?.answerList[2].text}
													</button>
													<button type={'button'} onClick={() => {
														setUserAnswer(4)
													}} className={`${userAnswer !== 4 ? 'bg-[#181818]' : 'bg-gradient-to-br'} w-full to-[#ff35dd] from-[#ea3379] p-[10px] rounded-[10px] flex items-center gap-3`}>
														{userAnswer !== 4 ? (
															<img
																src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/c4d5e1653d19d02ccd9490aa1dbb9a0e.png'}
																alt={''}/>
														) : (
															<img
																src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/7ed9034f85114318455fba5e38e3dad4.png'}
																alt={''}/>
														)}
														{quiz?.answerList[3].text}
													</button>
												</div>
											) : (
												<div>
													<div
														className={`${userAnswer !== 1 ? 'bg-[#181818]' : 'bg-gradient-to-br'}  to-[#ff35dd] from-[#ea3379] p-[10px] rounded-[10px] flex items-center gap-3`}>
														{
															userAnswer !== 1 ? (
																<img
																	src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/7328377b6f890508855a3d93f141d3f3.png'}
																	alt={''}/>
															) : (
																<img
																	src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/8aa2c15a59e6a99609a844260eeb38f0.png'}
																	alt={''}/>
															)
														}
														{quiz?.answerList[0].text}
													</div>
													<div
														className={`${userAnswer !== 2 ? 'bg-[#181818]' : 'bg-gradient-to-br'}  to-[#ff35dd] from-[#ea3379] p-[10px] rounded-[10px] flex items-center gap-3`}>
														{
															userAnswer !== 2 ? (
																<img
																	src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/074a44a85dc7b0c83cd02e88cc4c987c.png'}
																	alt={''}/>
															) : (
																<img
																	src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/0fd74fb491118bb78e09c1b648329fe3.png'}
																	alt={''}/>
															)
														}
														
														{quiz?.answerList[1].text}
													</div>
													<div
														className={`${userAnswer !== 3 ? 'bg-[#181818]' : 'bg-gradient-to-br'}  to-[#ff35dd] from-[#ea3379] p-[10px] rounded-[10px] flex items-center gap-3`}>
														{userAnswer !== 3 ? (
															<img
																src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/982c9010e709ecd8558f285b2bfa62b2.png'}
																alt={''}/>
														) : (
															<img
																src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/ffffd9db37d90dc7c7038e7218bebdf5.png'}
																alt={''}/>
														)}
														
														{quiz?.answerList[2].text}
													</div>
													<div
														className={`${userAnswer !== 4 ? 'bg-[#181818]' : 'bg-gradient-to-br'}  to-[#ff35dd] from-[#ea3379] p-[10px] rounded-[10px] flex items-center gap-3`}>
														{userAnswer !== 4 ? (
															<img
																src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/c4d5e1653d19d02ccd9490aa1dbb9a0e.png'}
																alt={''}/>
														) : (
															<img
																src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/7ed9034f85114318455fba5e38e3dad4.png'}
																alt={''}/>
														)}
														
														{quiz?.answerList[3].text}
													</div>
												</div>
											)
										}
									</div>
								</div>
							</div>
						)
					}
					
					{ quiz?.state === 'end' &&
						(
							<div className={'bg-[#282828] rounded-xl p-4 mt-4'}>
								{
									quiz?.answer.toString() === userAnswer.toString()? (
										<div className={'font-bold text-[16px]'}>
											👏 짝짝짝! 정답이에요!
										</div>
									) : (
										<div>
											<div className={'font-bold text-[16px]'}>
												🥲 아쉬워요! 오답이에요!
											</div>
											<div className={'text-yellow-400 flex items-center justify-center gap-1 mt-1'}>
												정답 : {
												quiz?.type === 'ox' ? (
													<div>
														{quiz?.answer === "1" ? 'O' : 'X'}
													</div>
												) : (
													<div>
														{quiz?.answer} 번
                                                    </div>
												)
											}
											</div>
										</div>
									)
								}
							</div>
						)
						
					}
				</div>
			</div>
		</div>
	)
}


export default function Player() {
	
	// 포스트 아이디
	const {postId} = useParams()
	
	// 포스트 설정
	const [post, setPost] = useState<Post | null>(null)
	
	// 모달 이벤트
	const [isOpen, setIsOpen] = useState(false)
	const [isEvent, setIsEvent] = useState(false)
	const [login, setLogin] = useState(false)
	
	// 모달 애니메이션
	const scope = ModalAnimation(isOpen)
	const eventScope = ModalAnimation(isEvent)
	const chatScope = ModalAnimation(login)
	
	
	// 유저 아이디
	const [uid, setUid] = useState('')
	
	// 채팅 리스트
	const [chatList, setChatList] = useState(null) as any
	
	// 조회수 카운트
	const [viewCnt, setViewCnt] = useState(0)
	const [viewerList, setViewerList] = useState<Viewer[]>([]);
	
	// 헤더 정보
	const [tempTitle, setTempTitle] = useState<HeaderInfo | null> ({
		title: '',
		view_cnt: 0,
		like_cnt: 0,
		state: '',
		type: ''
	})
	
	
	// 상품 정보
	const [tempProduct, setTempProduct] = useState<ProductInfo | null> ({
		product_link: '',
		product_link_text: '',
		product_name: '',
		product_thumb_url: ''
	})
	
	
	// 플레이어에 눈 내리는 효과
	const effectBody = useRef<HTMLDivElement>(null)
	// const snowEffect = () => {
	// 	for (let i = 0; i < 200; i++) {
	// 		const snow = document.createElement('div')
	// 		snow.className = 'snow'
	// 		snow.style.opacity = Math.random().toString();
	//
	// 		const startX = Math.random() * 100;
	// 		const endX = startX + (Math.random() * 20 - 10)
	//
	// 		const scale = Math.max(Math.random(), 0.5)
	//
	// 		const keyframes = [
	// 			{
	// 				transform: `translate(${startX}vw, 0) scale(${scale})`
	// 			},
	// 			{
	// 				transform: `translate(${endX}vw, 100dvh) scale(${scale})`
	// 			}
	// 		];
	//
	// 		const option = {
	// 			duration: 15000,
	// 			easing: 'linear',
	// 			iterations: Infinity,
	// 			delay: -Math.random() * 20 * 1000
	// 		}
	//
	// 		snow.animate(keyframes, option)
	//
	// 		if (effectBody.current) {
	// 			effectBody.current.appendChild(snow)
	// 		}
	// 	}
	// }
	
	
	async function syncPost() {
		if(!postId) return
		// post 값 가져오기
		onValue(ref(realdb, 'posts/' + postId), (snapshot) => {
			const data = snapshot.val()
			if(data) {
				setPost(data)
			}
		})
	}
	
	const syncChatList = async () => {
		if(!postId || !post) return;
		onValue(ref(realdb, 'chats/' + postId), (snapshot) => {
			const data = snapshot.val()
			if(data) {
				const userPosts = data
				let chats = []
				for (let c in userPosts) {
					chats.push(userPosts[c])
				}
				console.log(chats)
				setChatList(chats)
			}
		})
	}
	
	

	
	// 조회수 sync
	const syncViewer = async () => {
		if(!postId) return;
		// 누적 조회수 sync
		let database = ref(realdb, 'userView/' + postId)

		onValue(database, (snapshot) => {
			const data = snapshot.val()
			if(data) {
				const viewer = data
				let view = [] as  Array<Viewer>
				
				for (let v in viewer) {
                    view.push(viewer[v])
                }
				setViewCnt(view.length)
				setViewerList((prev) => [
					...prev,
					...view
				])
			}
		})
		
		
		// 실시간 유저 sync
		let database2 = ref(realdb, 'rooms/' + postId)
		onValue(database2, (snapshot) => {
			const data = snapshot.val()
			if(data) {
				const viewer = data
				let view = [] as  Array<Viewer>
				
				for (let v in viewer) {
					view.push(viewer[v])
				}
				
			}
		})
		
		// let database3 = ref(realdb, 'viewer/' + postId)
		
		// onValue(database3, (snapshot) => {
		// 	const data = snapshot.val()
		// 	if(data) {
		// 		const viewer = data
		// 		let view = [] as  Array<Viewer>
		//
		// 		for (let v in viewer) {
		// 			view.push(viewer[v])
		// 		}
		// 	}
		// })
		
		
		
		// const cookies = new Cookies()
		// const getcookies = cookies.get('uuid')
		// const nickname = cookies.get('nickname')
		// const viewerKey = push(child(ref(realdb), '/rooms/' + postId)).key
		//
		// const viewerUser = {
		// 	joinDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
		// 	ref: 'lguplus_'+dayjs().format('YYYYMMDD')+'_iphone',
		// 	userId: getcookies ? getcookies : uid,
		// 	userNick: nickname ? nickname : 'gest',
		// 	utm_campaign: getUtm().utm_campaign,
		// 	utm_content: getUtm().utm_content,
		// 	utm_medium: getUtm().utm_medium,
		// 	utm_source: getUtm().utm_source
		// }
		//
		//
		// set(child(ref(realdb), 'viewer/' + postId + '/' + viewerKey), viewerUser)
	}
	
	// utm 정보 가져오기
	const getUtm = () => {
		const utm = new URLSearchParams(window.location.search)
		const utm_campaign = utm.get('utm_campaign')
		const utm_content = utm.get('utm_content')
		const utm_medium = utm.get('utm_medium')
		const utm_source = utm.get('utm_source')
		
		return {
			utm_campaign: utm_campaign ? utm_campaign : '',
			utm_content: utm_content ? utm_content : '',
			utm_medium: utm_medium ? utm_medium : '',
			utm_source: utm_source ? utm_source : ''
		}
	}
	

	// 조회수 카운트
	const setViewer = () => {
		
		const cookies = new Cookies()
		const getcookies = cookies.get('uuid')
		const nickname = cookies.get('nickname')
		const viewerUser = {
			joinDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
			ref: 'lguplus_'+dayjs().format('YYYYMMDD')+'_iphone',
			userId: getcookies ? getcookies : uid,
			userNick: nickname ? nickname : 'gest',
            utm_campaign: getUtm().utm_campaign,
			utm_content: getUtm().utm_content,
			utm_medium: getUtm().utm_medium,
			utm_source: getUtm().utm_source
		}
	    let viewerKey = cookies.get('oid')
		if(viewerList.length > 0) {
			let viewer = viewerList.find((v) => v.userId === viewerUser.userId)
			if(viewer === null || viewer === undefined) {
				set(child(ref(realdb), 'userView/' + postId + '/' + viewerKey), viewerUser)
			}
		} else {
			set(child(ref(realdb), 'userView/' + postId + '/' + viewerKey), viewerUser)
		}
		
		
		if(post?.type === 'live') {
			set(child(ref(realdb), 'rooms/' + postId + '/' + viewerKey), viewerUser)

			onDisconnect(ref(realdb, 'rooms/' + postId + '/' + viewerKey)).set([])
		}
		
	}
	
	
	// 로그인
	const [onChat, setOnChat] = useState(false)
	
	const isLogin = () => {
		
		let loginVal = sessionStorage.getItem('nickname')
		
		if(loginVal) {
			setOnChat(true)
		} else {
			setLogin(true)
		}
	}
	useEffect(() => {
		// snowEffect()
		syncPost()
		syncViewer()
		syncChatList()
	}, []);
	
	// post 정보 업데이트
	useEffect(() => {
		if(post) {
			setTempTitle(post)
			setTempProduct(post)
		    setUid(post.uid)
		if(!post?.quiz) {
			setIsEvent(false)
		} else {
			if(post.quiz.state === 'end' && !isEvent) {
				setIsEvent(false)
				return
			}
			
			// if(quiz.state === 'ing' && !isEvent && quiz.ingTime < 10) {
			// 	setIsEvent(false)
			//     return
			// }
			setIsEvent(true)
		}
		 
		
		 const cookies = new Cookies()
		 
		 const getcookies = cookies.get('uuid')
		 // cookie 값이 없을 경우 uuid 생성
		 if(!getcookies) {
			 //uuid 생성
			 const uuid = crypto.randomUUID()
			 cookies.set('uuid', uuid.split('-')[4], {
				 expires: dayjs().add(1, 'day').startOf('day').toDate()
			 })
			 
			 let path = "";
			 
			 path = "/rooms/" + postId
			 let newViewer =  push(child(ref(realdb), (path = '/rooms/' + postId)));
			 cookies.set('oid', newViewer.key)
			 
			 setViewer()
		 }
			 console.log(post)
		 }
	}, [post]);
	
	
	useEffect(() => {
		setViewer()
		console.log(post)
	}, [viewerList]);
	
	useEffect(() => {
		if(!uid) return
		syncChatList()
	}, [uid])
	
	
	return (
		<div>
			{post && (
				<div className={'bg-black text-white'}
				     style={{lineHeight: '120%', letterSpacing: '-0.5px'}}>
					{
						post?.broadcast === 'on' ? (
							<div className={'flex justify-center items-center'}>
								{post.stream === 'on' ? (
									<div className={'w-screen max-w-[480px] relative h-full overflowe-hidden'}
									     style={{transform: 'translateZ(0)'}}>
										<div>
											<VideoPlayer
												src={post.live_url}/>
											<div ref={effectBody} id={'effectBody'}
											     className={'w-full h-[100px] absolute top-0 left-0'}></div>
										</div>
										<div className="fixed top-0 left-0 w-full z-[999]">
											<Header viewCnt={viewCnt} tempTitle={tempTitle}/>
										</div>
										<div className={'fixed w-full bottom-0 right-0 p-4 z-[999]'}>
											<div className={'flex  justify-between'}>
												<div className={'space-y-1 w-[80%]'}>
													<div>
														<RdbChat onChat={onChat} chatList={chatList} post={post} chatClose={() => {
															setOnChat(false)
														}}/>
													</div>
													<div>
														<Product tempProduct={tempProduct}/>
													</div>
												</div>
												<div className={''}>
													<div>
														<motion.button
															onClick={() => {
																setLogin(true)
															}}
															whileTap={{scale: 0.9}}
															className={'p-[10px]'}>
															<img
																src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/6047ae65ad8cf4323573eeb7b998374e.png"
																alt=""/>
														</motion.button>
													</div>
													<div>
														<motion.button whileTap={{scale: 0.9}}
														               className={'p-[10px]'}>
															<img
																src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/c543fe61befb29362e49f409218d9c5f.png'}
																alt={''}/>
														</motion.button>
													</div>
													<div>
														<motion.button type={'button'} onClick={isLogin} whileTap={{scale: 0.9}}
														               className={'p-[10px]'}>
															<img
																src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/e27ea4e9a7d06e1a4a0f72c30697affe.png'}
																alt={''}/>
														</motion.button>
													</div>
												</div>
											</div>
											<div>
												<div className={`w-full mt-3 mb-[-10px]`}>
													<motion.button
														id={'banner'}
														className={`w-full h-full my-[-30px]`}
														whileTap={{scale: 0.98}}
														onClick={() => {
															setIsOpen(true)
														}}>
														<img
															src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/5e3439f21fc84d4df58699951edde039.png"
															alt=""
															className={'w-full'}
														/>
													</motion.button>
												</div>
											</div>
										</div>
									</div>
								) : (
									<div className={'w-screen max-w-[480px] relative h-full overflowe-hidden'}
									     style={{transform: 'translateZ(0)'}}>
										<div>
											<img src={post.post_thumb_url} alt={''} className={'h-dvh object-cover'}/>
											<div
												className={'w-full h-dvh absolute top-0 left-0 z-[1] player__gradient'}></div>
											<div ref={effectBody} id={'effectBody'}
											     className={'w-full h-[100px] absolute top-0 left-0'}></div>
										</div>
										<div className="fixed top-0 left-0 w-full z-[999]">
											<Header viewCnt={viewCnt} tempTitle={tempTitle}/>
										</div>
										<div className={'fixed w-full bottom-0 right-0 p-4 z-[999]'}>
											<div className={'flex  justify-between'}>
												<div className={'space-y-1 w-[80%]'}>
													<div>
														<RdbChat onChat={onChat} chatList={chatList} post={post}
														          chatClose={() => {
															          setOnChat(false)
														          }}/>
													</div>
													<div>
														<Product tempProduct={tempProduct}/>
													</div>
												</div>
												<div className={''}>
													<div>
														<motion.button
															onClick={() => {
																setLogin(true)
															}}
															whileTap={{scale: 0.9}}
															className={'p-[10px]'}>
															<img
																src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/6047ae65ad8cf4323573eeb7b998374e.png"
																alt=""/>
														</motion.button>
													</div>
													<div>
														<motion.button whileTap={{scale: 0.9}}
														               className={'p-[10px]'}>
															<img
																src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/c543fe61befb29362e49f409218d9c5f.png'}
																alt={''}/>
														</motion.button>
													</div>
													<div>
														<motion.button type={'button'} onClick={isLogin}
														               whileTap={{scale: 0.9}}
														               className={'p-[10px]'}>
															<img
																src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/e27ea4e9a7d06e1a4a0f72c30697affe.png'}
																alt={''}/>
														</motion.button>
													</div>
												</div>
											</div>
											<div>
												<div className={`w-full mt-3 mb-[-10px]`}>
													<motion.button
														id={'banner'}
														className={`w-full h-full my-[-30px]`}
														whileTap={{scale: 0.98}}
														onClick={() => {
															setIsOpen(true)
														}}>
														<img
															src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/5e3439f21fc84d4df58699951edde039.png"
															alt=""
															className={'w-full'}
														/>
													</motion.button>
												</div>
											</div>
										</div>
									</div>
								)}
							
							</div>
						) : (
							<div className={'flex justify-center items-center'}>
								{
									post.type === 'vod' ? (
										<div className={'w-screen max-w-[480px] relative h-full overflowe-hidden'}
										     style={{transform: 'translateZ(0)'}}>
											<div>
												<VideoPlayer
													src={post.vod_url}/>
												<div ref={effectBody} id={'effectBody'}
												     className={'w-full h-[100px] absolute top-0 left-0'}></div>
											</div>
											<div className="fixed top-0 left-0 w-full z-[999]">
												<Header viewCnt={viewCnt} tempTitle={tempTitle}/>
											</div>
											<div className={'fixed w-full bottom-0 right-0 p-4 z-[999]'}>
												<div className={'flex  justify-between'}>
													<div className={'space-y-1 w-[80%]'}>
														<div>
															<RdbChat onChat={onChat} chatList={chatList} post={post}
															         chatClose={() => {
																         setOnChat(false)
															         }}/>
														</div>
														<div>
															<Product tempProduct={tempProduct}/>
														</div>
													</div>
													<div className={''}>
														<div>
															<motion.button
																onClick={() => {
																	setLogin(true)
																}}
																whileTap={{scale: 0.9}}
																className={'p-[10px]'}>
																<img
																	src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/6047ae65ad8cf4323573eeb7b998374e.png"
																	alt=""/>
															</motion.button>
														</div>
														<div>
															<motion.button whileTap={{scale: 0.9}}
															               className={'p-[10px]'}>
																<img
																	src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/c543fe61befb29362e49f409218d9c5f.png'}
																	alt={''}/>
															</motion.button>
														</div>
														<div>
															<motion.button type={'button'} onClick={isLogin}
															               whileTap={{scale: 0.9}}
															               className={'p-[10px]'}>
																<img
																	src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/e27ea4e9a7d06e1a4a0f72c30697affe.png'}
																	alt={''}/>
															</motion.button>
														</div>
													</div>
												</div>
												<div>
													<div className={`w-full mt-3 mb-[-10px]`}>
														<motion.button
															id={'banner'}
															className={`w-full h-full my-[-30px]`}
															whileTap={{scale: 0.98}}
															onClick={() => {
																setIsOpen(true)
															}}>
															<img
																src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/5e3439f21fc84d4df58699951edde039.png"
																alt=""
																className={'w-full'}
															/>
														</motion.button>
													</div>
												</div>
											</div>
										</div>
									) : (
										<div className={'w-screen max-w-[480px] relative h-full overflowe-hidden'}
										     style={{transform: 'translateZ(0)'}}>
											<div>
												<img src={post.post_thumb_url} alt="" className={'h-dvh object-cover'}/>
												<div ref={effectBody} id={'effectBody'}
												     className={'w-full h-[100px] absolute top-0 left-0'}></div>
											</div>
											<div className="fixed top-0 left-0 w-full z-[999]">
												<Header viewCnt={viewCnt} tempTitle={tempTitle}/>
											</div>
											<div className={'fixed w-full bottom-0 right-0 p-4 z-[999]'}>
												<div className={'flex  justify-between'}>
													<div className={'space-y-1 w-[80%]'}>
														<div>
															<RdbChat onChat={onChat} chatList={chatList} post={post}
															         chatClose={() => {
																         setOnChat(false)
															         }}/>
														</div>
														<div>
															<Product tempProduct={tempProduct}/>
														</div>
													</div>
													<div className={''}>
														<div>
															<motion.button
																onClick={() => {
																	setLogin(true)
																}}
																whileTap={{scale: 0.9}}
																className={'p-[10px]'}>
																<img
																	src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/6047ae65ad8cf4323573eeb7b998374e.png"
																	alt=""/>
															</motion.button>
														</div>
														<div>
															<motion.button whileTap={{scale: 0.9}}
															               className={'p-[10px]'}>
																<img
																	src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/c543fe61befb29362e49f409218d9c5f.png'}
																	alt={''}/>
															</motion.button>
														</div>
														<div>
															<motion.button type={'button'} onClick={isLogin}
															               whileTap={{scale: 0.9}}
															               className={'p-[10px]'}>
																<img
																	src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/e27ea4e9a7d06e1a4a0f72c30697affe.png'}
																	alt={''}/>
															</motion.button>
														</div>
													</div>
												</div>
												<div>
													<div className={`w-full mt-3 mb-[-10px]`}>
														<motion.button
															id={'banner'}
															className={`w-full h-full my-[-30px]`}
															whileTap={{scale: 0.98}}
															onClick={() => {
																setIsOpen(true)
															}}>
															<img
																src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/5e3439f21fc84d4df58699951edde039.png"
																alt=""
																className={'w-full'}
															/>
														</motion.button>
													</div>
												</div>
											</div>
										</div>
									)
								}
							</div>
						)
					}
				</div>
			)}
			<div>
				<div ref={scope}>
					<article>
						{
							isOpen && (
								<div>
									<Modal close={() => {
										setIsOpen(false)
									}}/>
								</div>
							)
						}
					</article>
				</div>
				<div ref={eventScope}>
					<article>
						{
							isEvent && (
								<div>
									<EventModal quiz={post?.quiz} close={() => {
										setIsEvent(false)
									}}/>
								</div>
							)
						}
					</article>
				</div>
				
				<div ref={chatScope}>
					<article>
						{
							login && (
								<div>
									<Login post={post} postId={postId} close={() => {
										setLogin(false)
									}}/>
								</div>
							)
						}
					</article>
				</div>
				
				{
					(isOpen || isEvent || login) && (
						<div className={'bg-[rgba(0,0,0,0.6)] w-full h-dvh fixed top-0 left-0'}></div>
					)
				}
			</div>
		</div>
	)
}
import React, {useEffect, useRef, useState} from "react";
import {motion} from "framer-motion";
import {CaretRight} from "@phosphor-icons/react";
import FlatList from 'flatlist-react'

import {ref, set, child, push} from "firebase/database";
import { realdb } from "../../../plugins/firebase";
import dayjs from "dayjs";
import {Cookies} from "react-cookie";
import {log} from "node:util";

const RdbChat = ({
	onChat,
	chatList,
	post,
	chatClose
}: {
	onChat: boolean,
	chatList: [{
		chatId: string,
		inactive: boolean,
		isAdmin: boolean,
		message: string,
		partnerId: string,
		regDate: string,
		type: string,
		userId: string,
		userNick: string,
	}],
	post: {
		broadcast_start_date: string,
		id: string,
		chat_state: string,
		uid: string,
		duration: number,
		type: string,
	} | null,
	chatClose: () => void,
}) => {
	const [sendChatList, setSendChatList] = useState([{
		chatId: '',
        inactive: false,
        isAdmin: false,
        message: '',
        partnerId: '',
        regDate: '',
        type: '',
        userId: '',
        userNick: '',
	}]) as any;
	
	
	const scrollHandle = useRef<HTMLDivElement>(null);
	
	const scrollToBottom = () => {
		if (scrollHandle.current) {
			scrollHandle.current.scrollIntoView({ behavior: "smooth" });
		}
	};
	
	useEffect(() => {
		
	if(sendChatList.length <= 0) return
		scrollToBottom();
	}, [sendChatList]);
	
	const [mobile, setMobile] = useState(false)
	
	useEffect(() => {
		setMobile(window.innerWidth < 768)
	}, []);
	
	useEffect(() => {
		if(!chatList || !post) return
		let list = chatList.filter((chat: any) => chat.inactive === true)
		for(let i = 0; i < list.length; i++) {
			let timer = post.type === 'live' ?  0 : dayjs(list[i].regDate).diff(dayjs(post.broadcast_start_date))
			setTimeout(() => {
				setSendChatList((prev: any) => [
                    ...prev,
					{
						chatId: list[i].chatId,
						inactive: list[i].inactive,
						isAdmin: list[i].isAdmin,
						message: list[i].message,
						partnerId: list[i].partnerId,
						regDate: list[i].regDate,
						type: list[i].type,
						userId: list[i].userId,
						userNick: list[i].userNick,
					}
                ])
			}, timer)
		}
	}, [chatList]);
	
	const sendChat = (e : any) => {
		e.preventDefault();
		
		const chats = e.target.chats.value;
		const chatRef = push(child(ref(realdb), ('/chats/' + post?.id))).key;
		const chatData = {
			chatId: chatRef,
			inactive: true,
			isAdmin: false,
			message: chats,
			partnerId: '',
			regDate: dayjs().toISOString(),
			type: 'msg',
			userId: sessionStorage.getItem('uid'),
			userNick: sessionStorage.getItem('nickname'),
		}
		
		set(ref(realdb, `chats/${post?.id}/${chatRef}`), chatData)
		
		chatClose()
	}
	return (
		<div className={'text-white'}>
			<div className={'chat_list mb-3 h-[100px] relative'}>
					<div
						className={`overflow-auto max-h-[100px] no-scroll leading-[150%] ${onChat ? 'w-full bottom-[30px]' : 'bottom-0'} absolute left-0`}>
						{
							sendChatList.length > 0 && (
								<FlatList
									list={sendChatList.slice(mobile ? -3 : -5)}
									renderItem={(chat: any, index) => {
										return (
											<div key={index}
											     className={`flex gap-2 text-[13px] ${chat.isAdmin ? 'text-yellow-400' : ''}`}>
												<div className={''}>
													<strong>{chat.userNick}</strong>
												</div>
												<div className={'flex-1'}>
													{chat.message}
												</div>
											</div>
										)
									}}
									renderWhenEmpty={() => <div className={'text-[13px]'}>라이브가 시작되었습니다.</div>}
								/>
							)
						}
						<div id={"scrollBottom"} ref={scrollHandle}></div>
					</div>
					<div className={'absolute w-full bottom-0'}>
						{
							onChat && (
								<form onSubmit={sendChat} className={'w-full block'}>
									<div className={'relative'}>
										<input type="text"
										       name={'chats'}
										       className={'py-[4px] pl-[8px] pr-[13px] bg-[#1a1a1a] border border-[#212121] rounded-[3.2px] w-full text-[rgba(199,205,228,1)] focus:outline-0'}
										
										/>
										<div className={'absolute top-0 right-0 p-2'}>
											<button type={'submit'}>전송</button>
										</div>
									</div>
								</form>
							)
						}
					</div>
			</div>
			<div
				className={'bg-black text-[12px] p-[3px] rounded-2xl shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] flex items-center justify-between'}>
				<div className={'flex items-center gap-2 truncate'}>
					<img
						src={'https://tkmenfxu2702.edge.naverncp.com/profile/202410/b0f7576a2b7f59c06459f04a317b4584.png'}
					alt={''}/>
					<div className={'overflow-hidden'}>
					<motion.div
						animate={{
						opacity: [1, 1, 1],
							translateX: [0, 0,'-110%']
					}}
					transition={{
						duration: 12,
							repeat: Infinity,
							repeatDelay: 3,
							ease: 'linear',
						
					}} className={'text-ellipsis max-w-[95%]'}>
						라이브 종료 시, 가격 및 혜택이 변경될 수 있습니다.
					</motion.div>
				</div>
			</div>
			<div>
				<CaretRight size={12} weight={'fill'}/>
			</div>
		</div>
	</div>
	)
}

export {
	RdbChat,
}
import {motion} from "framer-motion";
import {LineVertical} from "@phosphor-icons/react";

const Product = ({tempProduct}: {tempProduct: {
	    product_link: string,
		product_link_text: string,
        product_name: string,
        product_thumb_url: string,
	} | null}) => {
	
	const goProduct = () => {
		window.open(tempProduct?.product_link, '_blank');
	}
	return (
	<div className={''}>
		<button type={'button'} className={'w-full text-left'} onClick={goProduct}>
			<div
				className={'p-1 border-x-[0.5px] border-y-[0.5px] border-[rgba(0,0,0,0.00)] backdrop-blur-[12px] text-white rounded-md bg-[rgba(255,255,255,0.1)] drop-shadow-[0_0_14px_0_rgba(255,255,255,1)]'}>
				<div className={'flex items-center gap-3'}>
					<motion.div
						animate={{
							scale: [0, 1.2, 0.9, 1],
						}}
						transition={{
							duration: 0.3,
							ease: 'easeOut',
							delay: 0.5,
							repeat: Infinity,
							repeatDelay: 8
						}}
						className={'w-[42px] h-[42px]'}>
						<img
							src={tempProduct?.product_thumb_url}
							alt=""
							className={'w-full h-full rounded'}
						/>
					</motion.div>
					<div>
						<motion.div
							animate={{
								width: [0, '100%']
							}}
							transition={{
								delay: 1,
								repeat: Infinity,
								repeatDelay: 8
							}}
						>
							<p className={'font-bold flex items-center truncate text-[#ff6fe3]'}>
							<span className={'text-[#ff6fe3]'}>
								{tempProduct?.product_name}
							</span>
							</p>
						</motion.div>
						<motion.div
							animate={{
								width: [0, '100%']
							}}
							transition={{
								delay: 1.3,
								repeat: Infinity,
								repeatDelay: 8
							}}
						>
							<p className={'text-[12px] truncate text-[#ffb6f1]'}>
								{tempProduct?.product_link_text}
							</p>
						</motion.div>
					</div>
				</div>
			</div>
		</button>
	</div>
	)
}

export {
	Product,
}

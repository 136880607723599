import {Dot, DotsThreeOutlineVertical, Eye, Heart, SpeakerHigh, X} from "@phosphor-icons/react";
import {motion} from "framer-motion";
const Header = (
	{
		tempTitle,
		viewCnt
	}
	: {
		tempTitle: {
			title: string,
			view_cnt: number,
			like_cnt: number,
			state: string,
			type: string
		} | null,
		viewCnt: number
	}
) => {
	
	return (
		<div>
			<div className={'flex justify-between items-center p-4 '}>
				<div>
					<img
						src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/657894ebd05b191f0ecb410fdbcc2d37.png"
					alt=""/>
				</div>
				<div>
					<div className={'flex gap-2'}>
						<button className={'text-white'}>
							<DotsThreeOutlineVertical size={24} weight={'fill'}/>
						</button>
							<button className={'text-white'}>
								<SpeakerHigh size={24}/>
							</button>
						<button className={'text-white'}>
							<X size={24}/>
						</button>
					</div>
				</div>
			</div>
			<div className={'px-4 flex items-center gap-2'}>
				<p className="text-white truncate text-ellipsis flex-1">
				{tempTitle?.title}
				</p>
			</div>
			<div className="inline-block px-4">
				<div
					className="text-white text-sm flex items-center gap-2 bg-[rgba(0,0,0,0.47)] rounded-2xl mt-[0.4rem]">
				<div className={''}>
					{
						tempTitle?.state !== 'live' ? (
							<div>
								<img src="https://tkmenfxu2702.edge.naverncp.com/profile/202411/04f8ef361a98553129c27c068895c7ae.png" alt=""
								     className={'w-full h-full'}
								/>
							</div>
						) : (
							<img
								src="https://tkmenfxu2702.edge.naverncp.com/profile/202410/9c0e50ac90f4a72299952e03c6f9a167.png"
								alt="" className={'w-full h-full'}/>
						)
					}
			</div>
			<div className={'pr-2 flex items-center'}>
				<div className={'flex items-center gap-1 text-[13px]'}>
					<Eye size={14}/>
						{viewCnt?.toLocaleString()}
					</div>
					<Dot></Dot>
					<div className={'flex items-center gap-1 text-[13px]'}>
						<Heart size={14}/>
							{tempTitle?.like_cnt.toLocaleString()}
					</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export {
	Header,
}
'use client';

import Hls from 'hls.js'
import {useEffect, useRef} from "react";

const VideoPlayer = ({src}:{src: string}) => {
	const videoRef = useRef<HTMLVideoElement>(null)
	
	useEffect(() => {
		if(!videoRef.current) return
		
		if (Hls.isSupported()) {
			const hls = new Hls()
			hls.loadSource(src)
			hls.attachMedia(videoRef.current)
			hls.on(Hls.Events.MANIFEST_PARSED, function() {

				if(videoRef.current) videoRef.current.play()
			})
		} else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
			videoRef.current.src = src
			videoRef.current.addEventListener('loadedmetadata', function() {
				if(videoRef.current) videoRef.current.play()
			})
		}
	}, [src]);
	return (
		<div className={''}>
			<div className={'w-full h-dvh absolute top-0 left-0 z-[1] player__gradient'}></div>
			<video ref={videoRef} muted autoPlay={true} loop playsInline={true}
			       className={`object-cover w-full h-dvh`}/>
		</div>
	)
}

export {
	VideoPlayer,
}
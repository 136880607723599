
import {
	useAnimate,
} from 'framer-motion'
import {useEffect} from "react";


const ModalAnimation = (isOpen:boolean) => {
	const [scope, animate] = useAnimate()
	
	useEffect(() => {
		animate(
			"article" as any,
			isOpen ? {
				opacity: 1,
				scale: 1,
				filter: 'blur(0px)',
				position: 'fixed',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				zIndex: 999
			} : {
				opacity: 0,
				scale: 0.9,
				filter: 'blur(20px)',
				type: 'spring',
				bounce: 0,
				duration: 0.5,
				position: 'fixed',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				zIndex: 9999
			}
		)
	}, [isOpen]);
	return scope
}


export {
	ModalAnimation,
}